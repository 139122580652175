import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {LoginBackground, Logo} from 'assets';
import {AppState} from 'store/customer/storeSetup';

export interface Branding {
    available?: boolean; // this is not being set from anywhere, check and use
    login_logo?: string;
    background_image?: string;
    application_logo?: string;
    branding_manufacturer_name?: string;
    branding_manufacturer_favicon?: string;
    manufacturer_active?: boolean;
    permit_customer_self_registration?: boolean;
    font_normal?: string;
    font_bold?: string;
    font_italic?: string;
    is_custom_font?: boolean;
    allow_direct_login?: boolean;
    parent_application_url?: string;
    parent_application_name?: string;
    manufacturer_country_id?: number;
    branding_manufacturer_id?: number;
}

const initialState: Branding = {
    available: true,
    login_logo: `data:image/svg+xml;base64, ${btoa(String(Logo))}`,
    background_image: String(LoginBackground),
    branding_manufacturer_name: 'Cabinetry.Online',
    branding_manufacturer_favicon: '/v2/favicon.ico',
    manufacturer_active: true,
    permit_customer_self_registration: true,
    allow_direct_login: true,
};

const brandingSlice = createSlice({
    name: 'branding',
    initialState,
    reducers: {
        brandingSet: (state, {payload}: PayloadAction<Branding>) => {
            state.login_logo = payload.login_logo;
            state.application_logo = payload.application_logo;

            state.background_image =
                payload.background_image || state.background_image;
            state.branding_manufacturer_name =
                payload.branding_manufacturer_name ||
                state.branding_manufacturer_name;
            state.branding_manufacturer_favicon =
                payload.branding_manufacturer_favicon ||
                state.branding_manufacturer_favicon;
            state.manufacturer_active =
                payload.manufacturer_active !== null
                    ? payload.manufacturer_active
                    : state.manufacturer_active;
            state.permit_customer_self_registration =
                payload.permit_customer_self_registration !== null
                    ? payload.permit_customer_self_registration
                    : state.permit_customer_self_registration;
            state.allow_direct_login =
                payload.allow_direct_login !== null
                    ? payload.allow_direct_login
                    : state.allow_direct_login;
            state.parent_application_name =
                payload.parent_application_name ||
                state.parent_application_name;
            state.parent_application_url =
                payload.parent_application_url || state.parent_application_url;
            state.font_normal = payload.font_normal || state.font_normal;
            state.font_bold = payload.font_bold || state.font_bold;
            state.font_italic = payload.font_italic || state.font_italic;
            state.is_custom_font =
                payload.is_custom_font || state.is_custom_font;
            state.manufacturer_country_id =
                payload.manufacturer_country_id ||
                state.manufacturer_country_id;
            state.branding_manufacturer_id = payload.branding_manufacturer_id
                ? payload.branding_manufacturer_id
                : state.branding_manufacturer_id;
        },
        availableSet: (state, {payload}: PayloadAction<boolean>) => {
            state.available = payload;
        },
    },
});

export const selectBranding = (state: AppState) => state.theme;
export const selectLoginLogo = (state: AppState) => state.theme.login_logo;
export const selectBackgroundImage = (state: AppState) =>
    state.theme.background_image;
export const selectApplicationLogo = (state: AppState) =>
    state.theme.application_logo;
export const selectManufacturerName = (state: AppState) =>
    state.theme.branding_manufacturer_name;
export const selectManufacturerFavicon = (state: AppState) =>
    state.theme.branding_manufacturer_favicon;
export const selectManufacturerActive = (state: AppState) =>
    state.theme.manufacturer_active;
export const selectPermitCustomerSelfRegistration = (state: AppState) =>
    state.theme.permit_customer_self_registration;
export const selectAllowDirectLogin = (state: AppState) =>
    state.theme.allow_direct_login;
export const selectParentApplicationDetails = (state: AppState) => ({
    name: state.theme.parent_application_name,
    url: state.theme.parent_application_url,
});
export const selectManufacturerAvailable = (state: AppState) =>
    state.theme.available;
export const selectManufacturerCountryId = (state: AppState) =>
    state.theme.manufacturer_country_id;
export const selectManufacturerId = (state: AppState) =>
    state.theme.branding_manufacturer_id;
export default brandingSlice.reducer;

export const {brandingSet, availableSet} = brandingSlice.actions;
