import {AppApi} from 'store/customer/api';
import {ExtraVariation, Job} from 'components/customer/Job/entity/Job';
import {
    JobCabinetVariation,
    JobCabinetVariationApi,
} from 'components/customer/Job/entity/JobCabinetVariation';
import {mapVariation} from 'components/customer/Job/helpers/mapVariation';
import {
    FingerPullProduct,
    FingerPullProductApi,
} from 'components/customer/Job/entity/FingerPull';
import {mapFingerPull} from 'components/customer/Job/helpers/mapFingerPull';

export type MinimumUsage = {
    materialType: string;
    id: number;
    jobId: number;
    jobCabinetIds: string;
    benchtopIds: string | null;
    materialId: number;
    usage: number;
    calculatedSheet: number;
    rolloverUsage: number;
    minimumUsage: number;
    areaCost: number;
    type: string | null;
    doubleSidedCost: number;
    cost: number;
    measurement: string;
    products: string[];
    lengthCost: number;
    edgeId: number;
};

export interface DiscountItem {
    name: string;
    cost: number;
}

export interface BreakdownItem {
    name: string;
    cost: number;
    collapse?: boolean;
    items?: DiscountItem[];
}

type CouponDetail = {
    invalid: boolean;
    coupon_code: string;
    expiry_date: {
        date: string;
    };
};

type JobCost = {
    customerMinCharge: number;
    totalVariationCost: number;
    customerDiscount: string;
    supplierDiscount: number;
    freightCost: number;
    jobSubtotal: number;
    tax: number;
    totalJobCostExclTax: number;
    totalJobCost: number;
    totalMinUsage: number;
    supplierCommision: number;
    payPalSurcharge: number;
    maxJobSubtotal: number;
    coupon: null;
    couponDetails: CouponDetail;
    pricingMessages?: string[];
    pricingBreakdown?: BreakdownItem[];
    gfp_markup: number;
    minUsage: MinimumUsage[];
    additionServicesCost: number;
    jobMinChargeFromCustMin: number;
    supplierCommission: number;
};

interface JobResponse {
    displayId: number;
    jobId: number;
    messages: string[];
}

interface JobRoom {
    RoomNumber: number;
    RoomName: string;
    RoomDescription: string;
    CompanyLabel: string;
    InteriorMaterial: string;
    ExteriorMaterial: string;
    InteriorEdging: string;
    ExteriorEdging: string;
    DoorDwrProductName: string;
    HingeSeries: string;
    DrawerSystem: string;
    ToeKickHeight: string;
    JobCabinets: JobCabinet[];
}

export interface DrawerDetail {
    MaterialName: string;
    DrawerPrefix: string;
    DrawerFaceHeight: string;
    DrawerRunnerHeight: string;
    DrawerRunnerDepth: string;
}

export interface DrawerEdge {
    top: string;
    bottom: string;
    left: string;
    right: string;
}

export interface JobCabinet {
    RoomProductNo: string;
    Type: string;
    ProcessName: string;
    ProductDescription: string;
    Quantity: string;
    ProductHeight: string;
    ProductWidth: string;
    ProductDepth: string;
    ProductLength: string;
    KickHeight: string;
    KickWidth: string;
    KickDepth: string;
    TopChoice: string;
    ExteriorMaterial: string;
    ExteriorEdging: string;
    InteriorMaterial: string;
    InteriorEdging: string;
    ExcludeHardware: string;
    AdjustableLegs: string;
    DoorAmount: string;
    VerticalClearanceBetweenDoors?: string;
    BaseTopClearance: string;
    BaseBottomClearance: string;
    BaseLeftSideClearance: string;
    BaseRightSideClearance: string;
    SpecificHingeHeights: string;
    HingeSeries: string;
    DoorDwrProductName: string;
    DoorHang: string;
    Shelves: string;
    PartSetback: string;
    AssemblyNeeded: string;
    DrawerAmount: string;
    ClearanceBetweenDrawers: string;
    DrawerDetail: DrawerDetail[];
    DrawerEdging: DrawerEdge[];
    TallTopClearance: string;
    TallBottomClearance: string;
    TallLeftSideClearance: string;
    TallRightSideClearance: string;
    Image: string;
    ManuDesc: string;
    VariationRequest: string;
    QR: string;
    qrUrl: string;
    Comments: string;
}

export interface JobBenchtop {
    id: number;
    JobProductNo: string;
    Quantity: 1;
    Shape: string;
    Material: {
        'Material Type': string;
        Colour: string;
        Thickness: string;
    };
    PartCount: number;
    Dimensions: {
        A: number;
        B: number;
        C: number;
        D: number;
        E: number;
        F: number;
        G: number;
        H: number;
        I: number;
    };
    Joins: string[];
    ButtJoinDimensions: string[];
    ButtJoinName: string;
    Profiles: {
        A: string;
        B: string;
        C: string;
        D: string;
        E: string;
        F: string;
        G: string;
        H: string;
    };
    image: string;
    VariationRequest: string;
}

export interface JobSundry {
    HardwareNo: string;
    ItemName: string;
    ItemNo: number;
    Quantity: number;
    Brand: string;
    StockStatus: string;
    Fixing: string;
    'Height (mm)': string;
    imagePath: string;
}

export interface JobDetailsPreview {
    JobNumber: number;
    JobName: string;
    Status: string;
    Description: string;
    CustomerName: string;
    'Manufacturer Name': string;
    InvoiceTo: string;
    OrderDate: string;
    ExpectedDeliveryDate: string;
    DispatchMethod: string;
    ContactNumber: string;
    JobRooms: JobRoom[];
    jobBenchtops: JobBenchtop[];
    JobSundries: JobSundry[];
    deliveryAddress: string;
    customerEmail: string;
    title: string;
    generatedDate: string;
}

const JobApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobCost: builder.query<JobCost | null, {jobId: number}>({
            query: ({jobId}) => `job/${jobId}/cost`,
            transformResponse: (response: {cost: null}) => response.cost,
            providesTags: (response, error, {jobId}) => [
                {type: 'JobCost', jobId},
            ],
        }),
        // NOTE: This api can probably be used to fetch cost and products list
        // without additional network request. Sorry Nav. I should have thought
        // about this sooner. Will create a tech debt ticket right after to
        // consolidate all job and job related network request and data as
        // Updating jobContext is probably out of context for this ticket.
        getJob: builder.query<Job, {jobId: number}>({
            query: ({jobId}) => `jobs/${jobId}`,
            providesTags: (response, error, {jobId}) => [{type: 'Job', jobId}],
            transformResponse: (response: {data: Job}) => {
                const job = response.data;

                if (Array.isArray(job.rooms)) {
                    job.rooms = job.rooms.map((room, index) => {
                        room.roomNumber = index + 1;
                        if (room.jobBenchtops) {
                            room.jobBenchtops.map((product, productIndex) => {
                                product.productNumber = `${index + 1}-${
                                    productIndex + 1
                                }`;
                            });
                        }

                        return room;
                    });
                }

                return job;
            },
        }),
        getJobStatusStatistics: builder.query<string[], void>({
            query: () => 'jobs/statistics',
            providesTags: ['JobStatus'],
            transformResponse: (response: {data: string[]}) => response.data,
        }),
        jobReset: builder.mutation<void, {jobId: number; isReset?: boolean}>({
            query: ({jobId, isReset = true}) => ({
                url: `jobs/${jobId}/back-to-processing?isReset=${String(
                    isReset
                )}`,
                method: 'POST',
            }),
            invalidatesTags: [
                'Job',
                'Variations',
                'BTMBenches',
                'JobCost',
                'JobCabinetVariationDetails',
            ],
        }),
        confirmVariations: builder.mutation<void, {jobId: number}>({
            query: ({jobId}) => ({
                url: `jobs/${jobId}/updateJob`,
                method: 'POST',
            }),
            invalidatesTags: ['Variations', 'JobCabinetVariationDetails'],
        }),
        getExtraVariations: builder.query<ExtraVariation[], {jobId: number}>({
            query: ({jobId}) => `/job/extra-variations/${jobId}`,
            transformResponse: (response: {data: ExtraVariation[]}) => {
                return response.data;
            },
            providesTags: () => [{type: 'Variations'}],
        }),
        removeExtraVariation: builder.mutation<
            void,
            {
                data: {
                    ids: number[];
                };
            }
        >({
            query: ({data}) => ({
                url: `/job/remove-extra-variations`,
                method: 'POST',
                body: {
                    data: data,
                },
            }),
            invalidatesTags: ['Variations', 'JobCabinetVariationDetails'],
        }),
        updateRequestedDeliveryDate: builder.mutation<
            void,
            {jobId: number; requestedDeliveryDate: string}
        >({
            query: ({jobId, requestedDeliveryDate}) => ({
                url: `jobs/${jobId}/requested-delivery-date`,
                method: 'PUT',
                body: {
                    requestedDeliveryDate,
                },
            }),
            invalidatesTags: ['Job'],
        }),
        getJobCabinetVariationDetails: builder.query<
            JobCabinetVariation[],
            {jobId: number}
        >({
            query: ({jobId}) => `job/${jobId}/job-cabinet-variation-details`,
            transformResponse: (response: {
                variations: JobCabinetVariationApi[];
            }) => response.variations.map(mapVariation),
            providesTags: (response, error, {jobId}) => [
                {type: 'JobCabinetVariationDetails', jobId},
            ],
        }),
        deleteJobCabinetVariation: builder.mutation<void, {jobId: number}>({
            query: ({jobId}) => ({
                url: `job/${jobId}/job-cabinet-variation`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Variations', 'JobCabinetVariationDetails'],
        }),
        getFingerPullRecessedRails: builder.query<
            FingerPullProduct[],
            {jobId: number}
        >({
            query: ({jobId}) =>
                `jobs/${jobId}/get-finger-pull-and-recessed-rails`,
            transformResponse: (response: {
                job_cabinets: FingerPullProductApi[];
            }) => {
                return response.job_cabinets.map(mapFingerPull);
            },
        }),
        jobSave: builder.mutation<
            JobResponse,
            {data: FormData; jobId?: number}
        >({
            query: ({data, jobId}) => ({
                url:
                    typeof jobId === 'undefined'
                        ? 'jobs'
                        : `jobs/${jobId - 10000}`,
                method: typeof jobId === 'undefined' ? 'POST' : 'PUT',
                body: data,
            }),
            transformResponse: (response: JobResponse) => response,
            invalidatesTags: ['Job', 'JobCost'],
        }),
        getTotalProductCount: builder.query<number, number>({
            query: (jobId) => `jobs/${jobId - 10000}/total-product-count`,
            transformResponse: (response: {total_product_count: number}) =>
                response.total_product_count,
            providesTags: (response, error, jobId) => [
                {type: 'TotalProductCount', jobId},
            ],
        }),
        getJobDetailsPreview: builder.query<JobDetailsPreview, {jobId: number}>(
            {
                query: ({jobId}) => `job/${jobId}/preview/details`,
                providesTags: (response, error, {jobId}) => [
                    {type: 'JobDetailsPreview', jobId},
                ],
            }
        ),
        getJobPdfStatus: builder.query<
            {isJobQueued: number},
            {jobId: number; timestamp: number}
        >({
            query: ({jobId}) => `job/${jobId}/pdf/status`,
        }),
    }),
});

// remove this once we properly implement rtk query to invalidate tags
export const invalidateJobCost = () => JobApi.util.invalidateTags(['JobCost']);
export const invalidateJob = () => JobApi.util.invalidateTags(['Job']);
export const invalidateVariations = () =>
    JobApi.util.invalidateTags(['Job', 'Variations', 'BTMBenches', 'JobCost']);
export const invalidateTotalProductCount = () =>
    JobApi.util.invalidateTags(['TotalProductCount']);

export const {
    useGetJobCostQuery,
    useJobResetMutation,
    useConfirmVariationsMutation,
    useGetJobStatusStatisticsQuery,
    useLazyGetExtraVariationsQuery,
    useRemoveExtraVariationMutation,
    useUpdateRequestedDeliveryDateMutation,
    useLazyGetJobQuery,
    useGetJobCabinetVariationDetailsQuery,
    useLazyGetJobCabinetVariationDetailsQuery,
    useDeleteJobCabinetVariationMutation,
    useLazyGetFingerPullRecessedRailsQuery,
    useJobSaveMutation,
    useGetTotalProductCountQuery,
    useGetJobDetailsPreviewQuery,
    useGetJobPdfStatusQuery,
} = JobApi;
