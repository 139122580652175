import {
    LoginActionInterface,
    LoginActionResponse,
    UserAccount,
} from 'components/customer/Auth/store/authSlice';
import {AppApi} from 'store/customer/api';
import {Response} from 'service';
import {SIGNUP_SOURCES} from 'components/manufacturer/CustomerList/entity/CustomerList';

interface VerifyTokenInterface extends Response {
    email: string;
    accounts: UserAccount[];
}

interface LoginResponse extends Response {
    accounts?: UserAccount[];
    user_type?: number;
    domain?: string;
    redirectTo?: string;
}

interface SetPasswordRequest {
    id: string;
    token: string;
    password: string;
    confirmPassword: string;
}

interface PlaceInterface {
    id: string;
    name: string;
}

interface CountryDetailsInterface {
    id: number;
    countryName: string;
    postalCodeName: string;
    postalCodeLength: number;
}

export interface CountryInterface extends PlaceInterface {
    states: Array<PlaceInterface>;
    stateLabel: string;
}

interface RegisterRequestInterface {
    name: string;
    email: string;
    phone: string;
    country: string;
    customer_country_state: string;
    customer_suburb: string;
    customer_address: string;
    customer_address2: string;
    customer_postcode: string;
    business_name: string;
    abn: string;
    acn: string;
    web_url: string;
    manufacturer_id: number;
    depot_id: number;
}

interface ManufacturerInterface {
    email?: string;
    phone?: string;
}

export interface RegisterResponseInterface extends Response {
    email?: string;
    manufacturer?: ManufacturerInterface;
}

const tokenVerifyFormData = (token: string) => {
    const formData = new FormData();

    formData.set('token', token);

    return formData;
};

const loginActionInterfaceToFormData = ({
    username,
    password,
    keepLoggedIn,
}: LoginActionInterface) => {
    const formData = new FormData();

    formData.set('login_email', username);
    formData.set('login_password', password);
    formData.set('keep_logged', keepLoggedIn ? '1' : '0');

    return formData;
};

const DEFAULT_MANUFACTURER = 6;

const AccountApi = AppApi.injectEndpoints({
    endpoints: (build) => ({
        getAccounts: build.query<UserAccount[], void>({
            query: () => 'login/account',
            transformResponse: (response: {accounts: UserAccount[]}) =>
                response.accounts,
            providesTags: ['UserAccounts'],
        }),
        verifyToken: build.mutation<UserAccount[], string>({
            query: (token) => ({
                url: 'account/verify-password-reset-token',
                method: 'POST',
                body: tokenVerifyFormData(token),
            }),
            transformResponse: (response: VerifyTokenInterface) =>
                response.accounts,
            invalidatesTags: ['UserAccounts'],
        }),
        login: build.mutation<LoginActionResponse, LoginActionInterface>({
            query: (loginAction) => ({
                url: 'login',
                method: 'POST',
                body: loginActionInterfaceToFormData(loginAction),
            }),
            transformResponse: (response: LoginResponse, meta, arg) => {
                let accounts = response.accounts;
                const keepLoggedIn = arg.keepLoggedIn;

                if (typeof accounts === 'undefined') {
                    accounts = [
                        {
                            user_type: response.user_type,
                            domain: response.domain,
                            redirectTo: response.redirectTo,
                            id: null,
                            name: null,
                            logo: null,
                            passwordReset: null,
                            email: null,
                        },
                    ];
                }

                return {accounts, keepLoggedIn};
            },
            invalidatesTags: ['UserAccounts'],
        }),
        loginWithToken: build.mutation<LoginResponse, string>({
            query: (token) => ({
                url: `login-with-token`,
                method: 'POST',
                body: tokenVerifyFormData(token),
            }),
            invalidatesTags: ['UserAccounts'],
        }),
        verifyTokenSetPassword: build.mutation<UserAccount[], string>({
            query: (token) => ({
                url: 'account/verify-password-set-token',
                method: 'POST',
                body: tokenVerifyFormData(token),
            }),
            transformResponse: (response: VerifyTokenInterface) =>
                response.accounts,
            invalidatesTags: ['UserAccounts'],
        }),
        setPassword: build.mutation<void, SetPasswordRequest>({
            query({id, token, password, confirmPassword}) {
                const formData = new FormData();
                formData.set('id', `${id}`);
                formData.set('token', token);
                formData.set('password', password);
                formData.set('confirmPassword', confirmPassword);
                return {
                    url: `account/set-password`,
                    method: 'POST',
                    formData: true,
                    body: formData,
                };
            },
            invalidatesTags: ['UserAccounts'],
        }),
        getCountries: build.query<CountryInterface[], void>({
            query: () => 'countries',
            transformResponse: (response: {Countries: CountryInterface[]}) =>
                response.Countries,
            providesTags: ['Countries'],
        }),
        getCountry: build.query<
            CountryDetailsInterface,
            {countryId: number | string}
        >({
            query: ({countryId}) => `country/${countryId}`,
            transformResponse: (response: {country: CountryDetailsInterface}) =>
                response.country,
            providesTags: ['Country'],
        }),
        register: build.mutation<
            RegisterResponseInterface,
            RegisterRequestInterface
        >({
            query: ({manufacturer_id: manufacturerId, ...others}) => ({
                url: 'account/register_customer',
                method: 'POST',
                body: {
                    ...others,
                    manufacturerId: !!manufacturerId
                        ? manufacturerId
                        : DEFAULT_MANUFACTURER,
                    signup_source: !!manufacturerId
                        ? SIGNUP_SOURCES.SIGNUP_MANUFACTURER_DOMAIN
                        : SIGNUP_SOURCES.SIGNUP_GENERIC_DOMAIN,
                },
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAccountsQuery,
    useVerifyTokenMutation,
    useLoginMutation,
    useLoginWithTokenMutation,
    useVerifyTokenSetPasswordMutation,
    useSetPasswordMutation,
    useGetCountriesQuery,
    useLazyGetCountryQuery,
    useRegisterMutation,
} = AccountApi;
