import {Depots} from 'components/manufacturer/DepotSettings/depotApi';
import {AppApi} from 'store/customer/api';

const DepotApi = AppApi.injectEndpoints({
    endpoints: (build) => ({
        getDepots: build.query<Depots, void>({
            query: () => `/depots`,
            transformResponse: (response: {data: Depots}) => {
                return response.data;
            },
            providesTags: () => [
                {
                    type: 'Depot',
                },
            ],
        }),
        getCustomerDepots: build.query<Depots, void>({
            query: () => `/customer/depots`,
        }),
    }),
    overrideExisting: false,
});

export const {useGetDepotsQuery, useGetCustomerDepotsQuery} = DepotApi;
