import {ListenerEffectAPI, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppState, store} from 'store/customer/storeSetup';
import {
    coordinatesSet,
    cornerSet,
    cornersSet,
    edgeProfileSet,
    joinSet,
    joinsSet,
    pathsSet,
    sideSet,
    dimensionErrorsSet,
} from 'components/customer/BTM/store/btmSlice';
import {getCoordinates} from 'components/customer/BTM/helper/getCoordinates';
import {cutoffs} from 'components/customer/BTM/helper/corner';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {getPathsByCoordinates} from 'components/customer/BTM/helper/paths';
import {getJoins} from 'components/customer/BTM/helper/joins';
import {cloneDeep} from 'lodash';
import {Side} from 'components/customer/BTM/entity/Side';
import {Join} from 'components/customer/BTM/entity/Join';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {joinRestrictions} from 'components/customer/BTM/helper/joinRestrictions';
import {cornerRestrictions} from 'components/customer/BTM/helper/cornerRestrictions';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {getDimensionError} from 'components/customer/BTM/store/middleware/dimensionUpdateMiddleware';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {Path} from 'components/customer/BTM/entity/Path';
import {
    updateButtJoinByDimension,
    updateButtJoinByJoin,
} from 'components/customer/BTM/helper/updateButtJoin';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {setProfiledStateFromShapes} from 'components/customer/BTM/helper/useBlank';

/**
 * The function that is used by most of the middleware to update some data of bench
 * in relation with others
 *
 * @param {PayloadAction<Corner | number, string, {index: number}>} action Action that triggred this middleware
 * @param {ListenerEffectAPI<AppState, AppDispatch>} listenerApi
 * @param {boolean} setCorner
 * @return {void}
 */
const updateShape = async (
    action: PayloadAction<
        Corner | Corner[] | number,
        string | null,
        {index?: number; profile?: BenchtopEdgeProfile} | null
    >,
    listenerApi: ListenerEffectAPI<AppState, AppDispatch>,
    setCorner = false
) => {
    const state = listenerApi.getState();

    let initialCorners = cloneDeep(state.btm.corners);
    const dimension = state.btm.dimension;
    const shape = state.btm.type;
    const joins = state.btm.joins;
    const currentPaths = state.btm.paths;
    const edgeProfile = state.btm.edgeProfile;

    if (action.type == sideSet.type) {
        if (shape.type == Shape.USHAPE) {
            if ([Side.E].includes(action?.meta?.index)) {
                return;
            }
        } else if (shape.type == Shape.ANG) {
            if ([Side.C, Side.D].includes(action?.meta?.index)) {
                return;
            }
        }
    }

    if (action.type == sideSet.type && setCorner) {
        const depth = (action.payload as number) / 2 - 1;

        const conditions = {
            [Shape.SQR]: {sides: [Side.B], names: [] as string[][]},
            [Shape.ANG]: {
                sides: [Side.B, Side.E],
                names: [
                    ['AB', 'BC'],
                    ['DE', 'EF'],
                ],
            },
            [Shape.USHAPE]: {
                sides: [Side.C, Side.G],
                names: [
                    ['BC', 'CD'],
                    ['FG', 'GH'],
                ],
            },
        };

        const currentCondition = conditions[shape.type];

        const side = action.meta.index;
        if (currentCondition && currentCondition.sides.includes(side)) {
            const sideIndex = currentCondition.sides.indexOf(side);
            const names = currentCondition.names[Number(sideIndex)];

            initialCorners = initialCorners.map((corner) => {
                if (
                    ((names && names.includes(corner.name)) ||
                        typeof names == 'undefined') &&
                    corner.isArc
                ) {
                    const newDepths =
                        side == Side.B
                            ? [corner.depths[0], depth]
                            : [depth, corner.depths[1]];
                    return {...corner, depths: newDepths};
                }
                return corner;
            });
        }
    }

    const {coordinates} = getCoordinates(shape.type, dimension);
    const {points, corners} = cutoffs(initialCorners, coordinates);

    const paths = getPathsByCoordinates(
        points,
        currentPaths,
        action?.meta?.profile
    );

    if (shape.type != Shape.SQR) {
        let newJoins = getJoins(
            joins.filter((join) => join.joinType != JoinType.BUTT_JOIN),
            corners,
            dimension,
            shape.type
        );
        if (newJoins) {
            newJoins = joinRestrictions(newJoins, paths, edgeProfile);

            store.dispatch(joinsSet(newJoins));
        }
    }

    store.dispatch(coordinatesSet(points));
    store.dispatch(pathsSet(paths));

    if (setCorner) {
        const updatedCorners = await cornerRestrictions(corners, paths);
        store.dispatch(cornersSet(updatedCorners));
    }
};

const checkIfLengthIsValid = (length: number, corners: Corner[], index = 0) => {
    return !corners.some((corner) => {
        const depth = corner.depths[Number(index)];

        if (depth * 2 > length) {
            return true;
        }

        return false;
    });
};

/**
 * This function takes in dimensions, shape of bench, joins information,
 * and selected material data to check if the entered dimensions are
 * valid or not.
 *
 * @param {number[]} dimensions Dimensions of the bench
 * @param {Shape} shape shape of the bench e.g. Rectangle, U or L Shape
 * @param {Join[]} joins All the joins available for the bench look at btmSlice
 * @param {BenchtopMaterial} material The selected material
 * @param {Corner[]} corners The corners of the bench
 * @param {boolean} [filterEmpty] Whether or not to filter empty error messages
 * @param {string[]} messages The list of error messages
 * @return {string[]} The list of error messages
 */
export const getDimensionErrors = (
    dimensions: number[],
    shape: Shape,
    joins: Join[],
    material: BenchtopMaterial,
    corners: Corner[],
    filterEmpty = false,
    messages: string[] = []
) => {
    let dimensionMessages = cloneDeep(messages);
    const dimensionCopy = cloneDeep(dimensions);

    if (dimensionCopy.length == 8) {
        dimensionCopy.push(dimensionCopy[Side.H] - dimensionCopy[Side.F]);
    }

    dimensionCopy.forEach((length, side) => {
        const error = getDimensionError(
            side,
            shape,
            joins,
            material,
            dimensionCopy,
            length
        );

        if (error) {
            dimensionMessages[Number(side)] = error;
            return;
        }

        const arcCorners = corners.filter((corner) => corner?.isArc);

        if (arcCorners.length > 0) {
            let isValid = true;
            const conditions = {
                [Shape.SQR]: {sides: [Side.B], names: ['']},
                [Shape.ANG]: {sides: [Side.B, Side.E], names: ['AB', 'DE']},
                [Shape.USHAPE]: {sides: [Side.C, Side.G], names: ['BC', 'FG']},
            };

            // Safe: currentCondition is checked before being used.
            // eslint-disable-next-line security/detect-object-injection
            const currentCondition = conditions[shape];

            if (currentCondition && currentCondition.sides.includes(side)) {
                const name =
                    currentCondition.names[
                        currentCondition.sides.indexOf(side)
                    ];
                const corners = name
                    ? arcCorners.filter((corner) => corner.name == name)
                    : arcCorners;
                isValid = checkIfLengthIsValid(
                    length,
                    corners,
                    side == Side.B ? 0 : 1
                );
            }

            if (!isValid) {
                dimensionMessages[Number(side)] =
                    "Arc radius applied on one of the edges can't be more than specified depth";
                return;
            }
        }

        if (
            typeof dimensionMessages[Number(side)] == 'undefined' ||
            dimensionMessages[Number(side)].length == 0
        ) {
            dimensionMessages[Number(side)] = '';
        }
    });

    if (filterEmpty) {
        dimensionMessages = dimensionMessages.filter(
            (message) => message.length > 0
        );
    }

    return dimensionMessages;
};

export const dimensionUpdateEffect = () => ({
    actionCreator: sideSet,
    effect: (
        action: PayloadAction<
            number,
            string,
            {index: Side; updateButtJoin: boolean}
        >,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        void updateShape(action, listenerApi, true);

        const state = listenerApi.getState();

        // blanks
        if (state.btm?.material?.is_blank) {
            setProfiledStateFromShapes({
                dispatch: listenerApi.dispatch,
                shape: state.btm.type,
                dimensions: state.btm.dimension,
                material: state.btm.material,
                paths: state.btm.paths,
                edgeProfile: state.btm.edgeProfile,
                joins: state.btm.joins,
            });
        }

        const updateButtJoin =
            action?.meta?.updateButtJoin !== undefined
                ? action.meta.updateButtJoin
                : true;

        if (updateButtJoin) {
            // butt join
            updateButtJoinByDimension(action, listenerApi);
        }
    },
});

// don't recalculate joins when updating clips
export const cornerUpdateEffect = () => ({
    actionCreator: cornerSet,
    effect: (
        action: PayloadAction<Corner, string, {index: number}>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        void updateShape(action, listenerApi);
    },
});
// don't recalculate joins when updating clips
export const cornersUpdateEffect = () => ({
    actionCreator: cornersSet,
    effect: (
        action: PayloadAction<Corner[], null, null>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        void updateShape(action, listenerApi);
    },
});

export const profileSetEffect = () => ({
    actionCreator: edgeProfileSet,
    effect: async (
        action: PayloadAction<BenchtopEdgeProfile>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        const state = listenerApi.getState();
        const corners = state.btm.corners;
        const paths = state.btm.paths;
        const shape = state.btm.type;
        const joins = state.btm.joins;
        const edgeProfile = state.btm.edgeProfile;

        if (shape.type != Shape.SQR) {
            const newJoins = joinRestrictions(
                cloneDeep(
                    joins.filter((join) => join.joinType != JoinType.BUTT_JOIN)
                ),
                paths,
                edgeProfile
            );
            store.dispatch(joinsSet(newJoins));
        }

        const updatedCorners = await cornerRestrictions(corners, paths);
        listenerApi.dispatch(cornersSet(updatedCorners));
    },
});

export const joinSetEffectValidateLengths = () => ({
    actionCreator: joinSet,
    effect: (
        action: PayloadAction<
            boolean,
            string,
            {index?: number; side?: JoinSide}
        >,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        updateButtJoinByJoin(action, listenerApi);

        const state = listenerApi.getState();
        const dimensions = state.btm.dimension;
        const benchType = state.btm.type;
        const joins = state.btm.joins;
        const material = state.btm.material;
        const corners = state.btm.corners;
        const messages = state.btm.dimensionError;

        const dimensionMessages = getDimensionErrors(
            dimensions,
            benchType.type,
            joins,
            material,
            corners,
            false,
            messages
        );

        store.dispatch(dimensionErrorsSet(dimensionMessages));
    },
});

export const pathsSetEffect = () => ({
    actionCreator: pathsSet,
    effect: (
        action: PayloadAction<Path[], string, {updateJoin: boolean}>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        if (action && action.meta && action.meta.updateJoin) {
            const state = listenerApi.getState();
            const shape = state.btm.type;
            const joins = state.btm.joins;
            const edgeProfile = state.btm.edgeProfile;
            const paths = state.btm.paths;

            if (shape.type != Shape.SQR) {
                const newJoins = joinRestrictions(
                    cloneDeep(
                        joins.filter(
                            (join) => join.joinType != JoinType.BUTT_JOIN
                        )
                    ),
                    paths,
                    edgeProfile
                );

                store.dispatch(joinsSet(newJoins));
            }
        }
    },
});
