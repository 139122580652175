import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import {AppApi} from 'store/customer/api';

export interface PredictionInterface {
    id: string;
    name: string;
}

export interface PredictionDetailsInterface {
    streetNumber: string | null;
    streetName: string | null;
    city: string | null;
    suburb: string;
    state: string;
    postcode: string;
}

const transformDeliveryAddressData = (deliveryAddress: DeliveryAddress) => {
    return {
        id: deliveryAddress.id,
        name: deliveryAddress.name,
        street: deliveryAddress.street,
        city: !!deliveryAddress.city ? deliveryAddress.city : null,
        suburb: deliveryAddress.suburb,
        state: deliveryAddress.state,
        postcode: deliveryAddress.postcode,
        is_default: deliveryAddress.is_default,
    };
};

export const SettingsApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeliveryAddress: builder.query<DeliveryAddress[], void>({
            query: () => 'customer/delivery-address',
            transformResponse: (response: {
                deliveryAddress: DeliveryAddress[];
            }) =>
                response.deliveryAddress &&
                response.deliveryAddress.map((deliveryAddress) =>
                    transformDeliveryAddressData(deliveryAddress)
                ),
            providesTags: ['DeliveryAddress'],
        }),
        deleteDeliveryAddress: builder.mutation<void, {addressId: number}>({
            query: ({addressId}) => ({
                url: `customer/delivery-address/${addressId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DeliveryAddress'],
        }),
        saveDeliveryAddress: builder.mutation<
            DeliveryAddress,
            {address: DeliveryAddress}
        >({
            query: ({address}) => ({
                url: 'customer/delivery-address',
                method: 'POST',
                body: address,
            }),
            invalidatesTags: ['DeliveryAddress'],
        }),
        updateDefault: builder.mutation<void, {addressId: number}>({
            query: ({addressId}) => ({
                url: `customer/delivery-address/update-default/${addressId}`,
                method: 'POST',
            }),
            invalidatesTags: ['DeliveryAddress'],
        }),
        getPredictions: builder.query<
            PredictionInterface[],
            {input: string; country: string}
        >({
            query: ({input, country}) => {
                return {
                    url: 'address/search',
                    params: {input, country},
                };
            },
            transformResponse: (response: {
                predictions: PredictionInterface[];
            }) => response.predictions,
            providesTags: ['Predictions'],
        }),
        getPredictionDetails: builder.query<
            PredictionDetailsInterface,
            {placeId: string}
        >({
            query: ({placeId}) => {
                let url = 'address/details/?place_id=';
                url = url.concat(placeId);
                return url;
            },
            transformResponse: (response: {
                address: PredictionDetailsInterface;
            }) => response.address,
            providesTags: ['PredictionDetails'],
        }),
    }),
});

export const invalidateDeliveryAddress = () =>
    SettingsApi.util.invalidateTags(['DeliveryAddress']);

export const {
    useGetDeliveryAddressQuery,
    useDeleteDeliveryAddressMutation,
    useSaveDeliveryAddressMutation,
    useUpdateDefaultMutation,
    useLazyGetPredictionsQuery,
    useLazyGetPredictionDetailsQuery,
} = SettingsApi;
