import {AppApi} from 'store/customer/api';
import {
    Product,
    ProductForm,
    Validation,
    Structure,
    Cabinet,
} from 'components/customer/Product/entity';
import {mapHiddenMaterials} from 'components/customer/Product/helpers/mapHiddenMaterials';

type PreviewPayloadProps = {
    cabinet: Cabinet;
    job_cabinet: Product;
    majorDimensions?: string[][];
    structure: Structure[];
    success: number;
    validation?: Validation[];
};

type PreviewResultProps = {
    product_config: ProductForm;
    cabinet: Cabinet;
    job_cabinet: Product;
};

// The following contains the endpoints for 3d preview
const PreviewApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getCabinetPreview: builder.query<PreviewResultProps, {slug: string}>({
            query: ({slug}) => `/jobcabinet/${slug}/preview`,
            transformResponse: (response: PreviewPayloadProps) => {
                const result: PreviewResultProps = {
                    cabinet: response.cabinet,
                    job_cabinet: mapHiddenMaterials(response.job_cabinet),
                    product_config: {
                        majorDimensions: response.majorDimensions,
                        validation: response.validation,
                        structure: response.structure,
                    },
                };
                return result;
            },
        }),
    }),
});

export const {useGetCabinetPreviewQuery, useLazyGetCabinetPreviewQuery} =
    PreviewApi;
